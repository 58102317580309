import Vue from 'vue'
import VueRouter from 'vue-router'
import indexPage from '@/views/index.vue'
import createPage from '@/views/article/create.vue';
import detailPage from '@/views/article/detail.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: indexPage
  },
  {
    path: '/article/create',
    name: 'create',
    component: createPage
  },
  {
    path: '/article/detail',
    name: 'detail',
    component: detailPage
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
