import axios from 'axios';
/**
* 接口基础路径
* 生产: https://api.zhengjunan.cool/api
* 测试: http://localhost:3000/api
*/
const baseUrl = 'https://api.zhengjunan.cool/api';

// 响应超时时间 10s
axios.defaults.timeout = 10000;

// 配置请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        token && (config.headers.Authorization = token);
        return config;
    },
    error => {
        return Promise.error(error);
    })

// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 接口请求成功
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
            const msg = { data: { code: 408, message: '系统错误' } }
            return Promise.reject(msg);
        }
        // 接口请求失败
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    console.log('401: 未登录')
                    localStorage.removeItem('token')
                    localStorage.removeItem('userInfo')
                    break;
                case 403:
                    console.log('403: token过期')
                    localStorage.removeItem('token')
                    localStorage.removeItem('userInfo')
                    break;
                default:
                    console.log('其他错误')
                    localStorage.clear()
            }
            return Promise.reject(error.response);
        }
    });

/**
* get方法，对应get请求
* @param {String} url [请求的url地址]
* @param {Object} params [请求时携带的参数]
*/
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(`${baseUrl}${url}`, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(`${baseUrl}${url}`, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}