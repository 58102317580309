<template>
    <div class="index">
        <div class="content">
            <div class="header">
                <div class="box">
                    <div class="icon">
                        <img src="@/assets/blog.png" alt="logo">
                    </div>
                    <div class="search">
                        <van-search v-model="search" placeholder="请输入搜索关键词" @search="onSearch" />
                    </div>
                    <div class="user" v-if="token">
                        <img class="icon" src="@/assets/avatar.png" alt="avatar">
                    </div>
                    <van-button v-else type="danger" size="small" class="login"
                        @click="loginPopup = true">登录</van-button>
                </div>
            </div>
            <van-tabs v-model="active" sticky swipeable animated offset-top="50" @change="getDataList">
                <van-tab v-for="(item, index) in tabs" :key="index" :title="item">
                    <!-- 未登录 -->
                    <div class="empty" v-if="!token && active != 0">
                        <van-empty image="network" image-size="100" description="还未登录，是否立即前往？">
                            <van-button round type="danger" class="loginButton"
                                @click="loginPopup = true">立即登录</van-button>
                        </van-empty>
                    </div>
                    <!-- 加载中 -->
                    <div class="loading" v-else-if="loading">
                        <van-loading size="22px" vertical>加载中</van-loading>
                    </div>
                    <!-- 列表 -->
                    <div class="list" v-else-if="lists.length > 0">
                        <div v-for="(item, index) in lists" :key="index" class="item" @click="toDetail(item._id)">
                            <h1>{{ item.title }}</h1>
                            <p>{{ item.content }}</p>
                            <div class="info">
                                <div class="author">{{ item.username }} - {{ item.date }}</div>
                                <div class="tool">
                                    <div>
                                        <van-icon name="star-o" color="#8a919f" size="13" />
                                        {{ item.collect }}
                                    </div>
                                    <div>
                                        <van-icon name="like-o" color="#8a919f" size="13" />
                                        {{ item.like }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 消息提示 -->
                    <div class="empty" v-else>
                        <van-empty :image="emptyImage" image-size="100" :description="emptyDesc" />
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <div class="icp">
            <a href="https://beian.miit.gov.cn">ICP许可证号 粤ICP备2023016796号-1</a>
        </div>
        <!-- 创建文章 -->
        <div class="fab create" @click="createrticle" v-if="token">
            <van-icon name="plus" color="#ffffff" size="24" />
        </div>
        <!-- 退出登录 -->
        <div class="fab revoke" @click="revoke" v-if="active == 1 && token">
            <van-icon name="revoke" color="#ffffff" size="24" />
        </div>
        <!-- 登录 -->
        <van-popup v-model="loginPopup" round position="bottom" :safe-area-inset-bottom="true" :closeable="true">
            <login @loginSuccess="loginSuccess"></login>
        </van-popup>
    </div>
</template>

<script>
import { getOpennessArticles, getPersonalArticles, getLikeAndCollectArticles } from '@/request/api';
import login from '@/components/login.vue'
export default {
    name: 'indexPage',
    data() {
        return {
            token: '',
            search: '',
            isSearch: false,
            active: 0,
            tabs: ['广场', '我的', '喜欢', '收藏'],
            loading: false,
            lists: [],
            emptyImage: 'default',
            emptyDesc: '暂无数据',
            loginPopup: false
        }
    },
    watch: {
        search(newVal) {
            if (!newVal && this.isSearch) {
                this.isSearch = false
                this.getDataList(this.active)
            }
        }
    },
    components: {
        login
    },
    created() {
        const token = localStorage.getItem('token')
        this.token = token || ''
    },
    mounted() {
        // 设置tab激活状态
        let active = localStorage.getItem('tabActive') || 0
        active = Number(active)
        this.getDataList(active)
    },
    methods: {
        // 搜索
        onSearch() {
            if (this.search) {
                this.isSearch = true
                this.getDataList(this.active)
            }
        },
        // 获取列表数据
        getDataList(index) {
            this.loading = true
            this.lists = []
            this.active = index
            localStorage.setItem('tabActive', index)
            switch (index) {
                case 0:
                    this.getOpennessArticles()
                    break;
                case 1:
                    this.getPersonalArticles()
                    break;
                case 2:
                    this.getLikeAndCollectArticles('like')
                    break;
                case 3:
                    this.getLikeAndCollectArticles('collect')
                    break;
                default:
                    this.emptyImage = 'default'
                    this.emptyDesc = '系统错误'
                    break;
            }
        },
        // 登录成功
        loginSuccess(token) {
            this.loginPopup = false
            this.token = token || ''
            this.getDataList(this.active)
        },
        // 获取广场文章列表
        getOpennessArticles() {
            const params = {
                search: this.search
            }
            getOpennessArticles(params).then(res => {
                let { articles } = res
                if (articles.length == 0) {
                    this.isAxiosError('暂无数据')
                    return
                }
                this.loading = false
                this.lists = articles;
            }).catch((error) => {
                let { message } = error
                this.isAxiosError(message)
            });
        },
        // 获取个人文章列表
        getPersonalArticles() {
            const params = {
                search: this.search
            }
            getPersonalArticles(params).then(res => {
                let { articles } = res
                if (articles.length == 0) {
                    this.isAxiosError('暂无数据')
                    return
                }
                this.loading = false
                this.lists = articles;
            }).catch((error) => {
                let { code, message } = error
                if (code == 401 || code == 403) {
                    this.token = ''
                }
                this.isAxiosError(message)
            });
        },
        // 获取个人点赞和收藏文章列表
        getLikeAndCollectArticles(type) {
            let params = {
                type: type,
                search: this.search
            }
            getLikeAndCollectArticles(params).then(res => {
                let { articles } = res
                if (articles.length == 0) {
                    this.isAxiosError('暂无数据')
                    return
                }
                this.loading = false
                this.lists = articles;
            }).catch((error) => {
                let { code, message } = error
                if (code == 401 || code == 403) {
                    this.token = ''
                }
                this.isAxiosError(message)
            });
        },
        // 创建文章
        createrticle() {
            if (this.token) {
                this.$router.push('/article/create')
            } else {
                this.loginPopup = true
            }
        },
        // 退出登录
        revoke() {
            this.$dialog.confirm({
                title: '提示 ',
                message: '是否退出登录？',
                confirmButtonText: '确认',
                confirmButtonColor: '#ee0a24',
                cancelButtonText: '取消',
                cancelButtonColor: '#8a919f'
            }).then(() => {
                this.lists = []
                this.token = ''
                localStorage.removeItem('token')
            }).catch(() => {
                console.log('取消退出')
            });
        },
        // 前往详情
        toDetail(id) {
            this.$router.push({ path: '/article/detail', query: { id: id } })
        },
        // 错误处理
        isAxiosError(message) {
            this.lists = []
            this.loading = false
            this.emptyImage = 'error'
            this.emptyDesc = message
        }
    }
}
</script>

<style lang="scss" scoped>
.index {
    .header {
        position: sticky;
        top: 0;
        z-index: 100;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 20px;
        height: 50px;
        background-color: #ffffff;

        .box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 40px;

            .icon {
                width: 40px;
                height: 40px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .search {
                flex: 1;
                padding: 0 15px;

                .van-search {
                    padding: 0;

                    .van-search__content {
                        background-color: rgba($color: #5c5c5c, $alpha: 0.1);
                    }
                }
            }

            .user {
                width: 36px;
                height: 36px;
                background-color: rgba($color: #5c5c5c, $alpha: 0.1);
                border-radius: 36px;
                overflow: hidden;

                .icon {
                    margin: 3px;
                    width: 30px;
                    height: 30px;
                }

                .avatar {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .van-button--small {
                padding: 0;
                width: 50px;
                height: 34px;
            }
        }
    }

    .content {
        min-height: calc(100vh - 55px);

        /deep/.van-tabs__wrap {
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
        }

        .loginButton {
            width: 150px;
            height: 40px;
            background-color: #ee0a24;
            border-color: #ee0a24;
        }

        .loading {
            padding: 20px 0;
        }

        .list {
            padding: 0 20px;

            .item {
                padding: 20px 0;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

                h1 {
                    font-size: 16px;
                    line-height: 24px;
                    word-break: break-all;
                    color: #252933;
                }

                p {
                    margin: 2px 0 8px 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-wrap: break-word;
                    word-break: normal;
                    white-space: normal;
                    font-size: 13px;
                    line-height: 22px;
                    color: #8a919f;
                }

                .info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .author {
                        font-size: 13px;
                        color: #8a919f;
                    }

                    .tool {
                        display: flex;
                        align-items: center;

                        div {
                            margin-left: 5px;
                            font-size: 13px;
                            color: #8a919f;
                        }
                    }
                }

            }
        }
    }

    .icp {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;

        a {

            font-size: 12px;
            color: #8a919f;
        }
    }

    .fab {
        position: fixed;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border-radius: 46px;
    }

    .create {
        bottom: 130px;
        background-color: #ee0a24;
    }

    .revoke {
        bottom: 60px;
        background-color: #ff976a;
    }
}
</style>