/**   
 * api接口统一管理
 */
import { get, post } from './http'

// 登录
export const login = params => post('/login', params);

// 注册
export const register = params => post('/register', params);

// 创建文章
export const createOrUpdataArticles = params => post('/createOrUpdataArticles', params);

// 广场文章列表
export const getOpennessArticles = params => post('/getOpennessArticles', params);

// 个人文章列表
export const getPersonalArticles = params => post('/getPersonalArticles', params);

// 个人点赞和收藏列表
export const getLikeAndCollectArticles = params => post('/getLikeAndCollectArticles', params);

// 文章详情
export const getArticlesDetail = params => post('/getArticlesDetail', params);

// 删除文章
export const delArticle = params => post('/delArticle', params);

// 获取用户是否点赞或收藏
export const isLikeOrCollect = params => get('/isLikeOrCollect', params);

// 点赞文章
export const likeOrCollectArticles = params => post('/likeOrCollectArticles', params);

// 上传图片
export const uploadImage = params => post('/uploadImage', params);

// 获取图片
export const getPrivateImg = params => post('/getPrivateImg', params);