<template>
    <div class="detail">
        <div class="header">
            <h1>{{ article.title }}</h1>
            <h2>{{ article.username }}</h2>
            <div class="date">
                <p>{{ article.date }}{{ article.time }}</p>
                <div v-if="userId === article.author">
                    <van-popover v-model="showPopover" trigger="click" :actions="actions" @select="onSelect"
                        placement="left">
                        <template #reference>
                            <van-icon name="weapp-nav" color="#8a919f" size="16" />
                        </template>
                    </van-popover>
                </div>
            </div>
        </div>
        <div class="content" v-html="article.content"></div>
        <div class="tool like" @click="likeOrCollect('like')">
            <van-icon name="like" :color="like ? '#ee0a24' : '#8a919f'" size="20" />
            <p :style="{ color: like ? '#ee0a24' : '#8a919f' }">{{ article.like }}</p>
        </div>
        <div class="tool collect" @click="likeOrCollect('collect')">
            <van-icon name="star" :color="collect ? '#ff976a' : '#8a919f'" size="20" />
            <p :style="{ color: collect ? '#ff976a' : '#8a919f' }">{{ article.collect }}</p>
        </div>
        <!-- 登录 -->
        <van-popup v-model="loginPopup" round position="bottom" :safe-area-inset-bottom="true" :closeable="true">
            <login @loginSuccess="loginSuccess"></login>
        </van-popup>
    </div>
</template>

<script>
import { getArticlesDetail, likeOrCollectArticles, isLikeOrCollect, delArticle } from '@/request/api'
import login from '@/components/login.vue'
export default {
    name: 'detailPage',
    data() {
        return {
            token: '',
            userId: '',
            id: '',
            showPopover: false,
            actions: [
                {
                    type: 'edit',
                    text: '修改',
                    icon: 'edit'
                },
                {
                    type: 'del',
                    text: '删除',
                    icon: 'delete-o'
                }
            ],
            article: {},
            like: false,
            collect: false,
            isDisabled: false,
            loginPopup: false
        }
    },
    components: {
        login
    },
    created() {
        const { id } = this.$route.query;
        this.id = id
        this.getArticlesDetail()
        this.initUserInfo()
    },
    methods: {
        // 获取用户信息
        initUserInfo() {
            let userInfo = localStorage.getItem('userInfo')
            userInfo = JSON.parse(userInfo)
            this.userId = userInfo?._id
            const token = localStorage.getItem('token')
            this.token = token || ''
            if (token) {
                this.isLikeOrCollect()
            }
        },
        // 获取文章详情
        getArticlesDetail() {
            this.$toast({
                type: 'loading',
                message: '加载中...',
                duration: 0
            })
            let params = {
                id: this.id
            }
            getArticlesDetail(params).then(res => {
                this.$toast.clear();
                let { article } = res
                this.article = article
            }).catch((error) => {
                this.$toast.clear();
                let { message } = error
                this.$toast(message);
            });
        },
        // 登录成功
        loginSuccess() {
            this.loginPopup = false
            this.initUserInfo()
            this.isLikeOrCollect()
        },
        // 点赞或收藏
        likeOrCollect(type) {
            if (this.isDisabled) return
            if (!this.token) {
                this.loginPopup = true
                return
            }
            this.isDisabled = true
            let params = {
                articleId: this.id,
                type: type
            }
            likeOrCollectArticles(params).then(() => {
                this.isDisabled = false
                if (type === 'like') {
                    this.like ? this.article.like-- : this.article.like++
                    this.like = !this.like
                } else {
                    this.collect ? this.article.collect-- : this.article.collect++
                    this.collect = !this.collect
                }
            }).catch((error) => {
                this.isDisabled = false
                this.errorAxios(error)
            })
        },
        // 获取是否已点赞或收藏
        isLikeOrCollect() {
            let params = {
                articleId: this.id
            }
            isLikeOrCollect(params).then(res => {
                let { isCollect, isLike } = res
                this.like = isLike
                this.collect = isCollect
            }).catch((error) => {
                this.errorAxios(error)
            })
        },
        // 更多设置
        onSelect(action) {
            const { type } = action
            const message = type == 'del' ? '是否确认删除此篇文章？' : '是否确认修改此篇文章？'
            this.$dialog.confirm({
                title: '提示 ',
                message: message,
                confirmButtonText: '确认',
                confirmButtonColor: '#ee0a24',
                cancelButtonText: '取消',
                cancelButtonColor: '#8a919f'
            }).then(() => {
                if (type == 'del') {
                    this.delArticle()
                } else {
                    this.$router.push({ name: 'create', query: { id: this.id } })
                }
            }).catch(() => {
            });
        },
        // 删除文章
        delArticle() {
            let params = {
                id: this.id
            }
            delArticle(params).then(() => {
                this.$toast('删除成功')
                setTimeout(() => {
                    this.$router.go(-1);
                }, 500)
            }).catch((error) => {
                this.errorAxios(error)
            })
        },
        // 错误操作
        errorAxios(error) {
            let { code, message } = error
            if (code == 401 || code == 403) {
                this.token = ''
            }
            this.$toast(message);
        }
    }
}
</script>

<style lang="scss" scoped>
.detail {
    padding: 0 20px;
    min-height: 100vh;
    background-color: #f2f3f5;

    .header {
        padding: 20px 0;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);

        h1 {
            font-size: 16px;
            font-weight: 600;
            color: #252933;
        }

        h2 {
            padding: 5px 0;
            font-size: 13px;
            font-weight: normal;
            color: #8a919f;
        }

        .date {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                font-size: 13px;
                color: #8a919f;
            }
        }
    }

    .content {
        padding: 20px 0;
        line-height: 22px;
        font-size: 14px;
        color: #252933;
        white-space: pre-wrap;
    }

    .tool {
        position: fixed;
        right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border-radius: 46px;
        background-color: #ffffff;
        box-shadow: 2 2px 4px 0 rgba(50, 50, 50, .04);

        p {
            font-size: 12px;
            color: #8a919f;
        }
    }

    .like {
        bottom: 150px;
    }

    .collect {
        bottom: 80px;
    }
}
</style>