<template>
    <div class="login">
        <!-- <h1>登录畅享更多权益</h1> -->
        <h2>{{ isLogin ? '密码登录' : '立即注册' }}</h2>
        <van-field v-model="username" maxlength="10" placeholder="请输入用户名" :border="false" />
        <van-field v-model="password" type="password" placeholder="请输入密码" :border="false" />
        <van-field v-model="passwordAgain" v-if="!isLogin" type="password" placeholder="请再次确认密码" :border="false" />
        <div class="switch" @click="isLogin = !isLogin">
            <!-- <van-icon name="exchange" color="#8a919f" size="12" /> {{ isLogin ? '没有账号？去注册' : '已有账号？去登录' }} -->
        </div>
        <div class="btnGroup">
            <van-button type="primary" v-if="isLogin" @click="login" :loading='loginLoad'
                loading-text="登录中...">登录</van-button>
            <van-button plain type="info" v-else @click="register" :loading='registerLoad'
                loading-text="注册中...">注册并登录</van-button>
        </div>
        <div class="agreement">
            <!-- 注册登录即表示同意<a>用户协议</a>和<a>隐私政策</a> -->
        </div>
    </div>
</template>

<script>
import { login, register } from '@/request/api';
export default {
    name: 'loginPage',
    data() {
        return {
            isLogin: true,
            username: '',
            password: '',
            passwordAgain: '',
            loginLoad: false,
            registerLoad: false
        }
    },
    methods: {
        // 登录
        login() {
            if (!this.username || !this.password) {
                this.$toast('请输入用户名和密码');
                return;
            }
            this.loginLoad = true
            let params = {
                username: this.username,
                password: this.password
            }
            login(params).then(res => {
                this.loginLoad = false
                let { user, token, message } = res
                this.$toast(message);
                localStorage.setItem('token', token)
                localStorage.setItem('userInfo', JSON.stringify(user))
                this.$emit('loginSuccess', token)
            }).catch((error) => {
                this.loginLoad = false
                let { message } = error
                this.$toast(message);
            });
        },
        // 注册
        register() {
            if (!this.username || !this.password || !this.passwordAgain) {
                this.$toast('请输入用户名和密码');
                return;
            }
            if (this.password !== this.passwordAgain) {
                this.$toast('密码不一致');
                return;
            }
            this.registerLoad = true
            let params = {
                username: this.username,
                password: this.password
            }
            register(params).then(res => {
                this.registerLoad = false
                let { user, token, message } = res
                this.$toast(message);
                localStorage.setItem('token', token)
                localStorage.setItem('userInfo', JSON.stringify(user))
                this.$emit('loginSuccess', JSON.stringify(user))
            }).catch((error) => {
                this.registerLoad = false
                let { message } = error
                this.$toast(message);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.login {
    padding: 0 20px 20px 20px;

    h1 {
        height: 54px;
        line-height: 54px;
        font-size: 16px;
        font-weight: 600;
        color: #252933;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
    }

    h2 {
        margin: 15px 0;
        font-size: 15px;
        font-weight: normal;
        color: #252933;
    }

    .van-field {
        margin-bottom: 15px;
        padding: 0 15px;
        line-height: 40px;
        background-color: #f2f3f5;
        border-radius: 2px;
    }

    .switch {
        margin-bottom: 15px;
        text-align: right;
        font-size: 12px;
        color: #8a919f;
    }

    .btnGroup {
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        .van-button {
            height: 40px;
        }

        .van-button--primary {
            flex: 1;
            background-color: #ee0a24;
            border-color: #ee0a24;
        }

        .van-button--info {
            flex: 1;
            color: #ee0a24;
            border-color: #ee0a24;
            background-color: rgba(238, 10, 36, 0.1);
        }
    }

    .agreement {
        font-size: 12px;
        color: #8a919f;
        text-align: center;

        a {
            padding: 0 2px;
            color: #ee0a24;
        }
    }
}
</style>