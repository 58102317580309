<template>
    <div class="create">
        <van-field v-model="title" name="标题" label="标题" placeholder="请输入标题" />
        <van-field name="checkbox" label="是否发布广场">
            <template #input>
                <van-checkbox v-model="openness" shape="square" checked-color="#ee0a24" icon-size="16px" />
            </template>
        </van-field>
        <van-field class="textarea" v-model="content" rows="8" label="内容" type="textarea" maxlength="1000"
            placeholder="请输入内容" show-word-limit />
        <van-field name="uploader" label="封面上传">
            <template #input>
                <van-uploader v-model="uploader" :after-read="afterRead" :max-count="1" />
            </template>
        </van-field>
        <van-button type="primary" @click="submit" :loading='submitLoad' :loading-text="id ? '修改中...' : '提交中...'">
            {{ id ? '修改' : '提交' }}
        </van-button>
    </div>
</template>

<script>
import { getArticlesDetail, createOrUpdataArticles, uploadImage, getPrivateImg } from '@/request/api';
export default {
    name: 'createPage',
    data() {
        return {
            id: '',
            title: '',
            openness: false,
            content: '',
            uploader: [],
            submitLoad: false
        }
    },
    created() {
        const { id } = this.$route.query;
        if (id) {
            this.id = id
            this.getArticlesDetail()
        }
    },
    methods: {
        // 获取文章详情
        getArticlesDetail() {
            this.$toast({
                type: 'loading',
                message: '加载中...',
                duration: 0
            })
            let params = {
                id: this.id
            }
            getArticlesDetail(params).then(res => {
                this.$toast.clear();
                let { article: { title, content, openness } } = res
                this.title = title
                this.content = content
                this.openness = openness
            }).catch((error) => {
                this.$toast.clear();
                let { message } = error
                this.$toast(message);
            });
        },
        // 回调函数-上传完成
        afterRead(file) {
            const formData = new FormData();
            formData.append('file', file.file);
            console.log(file)
            uploadImage(formData).then(res => {
                const { name } = res
                this.getPrivateImg(name)
            }).catch((error) => {
                let { message } = error
                this.$toast(message);
            })
        },
        // 获取私有图片路径
        getPrivateImg(name) {
            const params = { filePath: name }
            getPrivateImg(params).then(res => {
                const { content } = res
                console.log(res)
            }).catch((error) => {
                let { message } = error
                this.$toast(message);
            })
        },
        // 提交
        submit() {
            if (!this.title) {
                this.$toast('请输入标题')
                return
            }
            if (!this.content) {
                this.$toast('请输入内容')
                return
            }
            const params = {
                id: this.id,
                title: this.title,
                content: this.content,
                openness: this.openness
            }
            this.submitLoad = true
            createOrUpdataArticles(params).then(res => {
                this.submitLoad = false
                let { article } = res
                const message = this.id ? '修改成功，是否跳转到详情页' : '创建成功，是否跳转到详情页'
                this.$dialog.confirm({
                    title: '提示 ',
                    message: message,
                    confirmButtonText: '立即前往',
                    confirmButtonColor: '#ee0a24',
                    cancelButtonText: '取消',
                    cancelButtonColor: '#8a919f'
                }).then(() => {
                    if (this.id) {
                        this.$router.go(-1)
                    } else {
                        this.$router.push({ path: '/article/detail', query: { id: article._id } })
                    }
                }).catch(() => {
                    if (!this.id) {
                        this.$router.go(-1)
                    }
                });
            }).catch((error) => {
                this.submitLoad = false
                let { message } = error
                this.$toast(message);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.create {
    padding: 10px;

    /deep/.textarea .van-field__value {
        padding: 5px;
        background-color: #f7f8fa;
    }

    .van-button {
        margin-top: 20px;
        width: 100%;
        height: 40px;
    }

    .van-button--primary {
        flex: 1;
        background-color: #ee0a24;
        border-color: #ee0a24;
    }
}
</style>